import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`dmc`, `insurance`, `products`]} />
    <div css={{ padding: ['24px 0', '48px 0', '72px 0'] }}>
      <p
        css={{
          position: 'relative',
          '&:before': {
            position: 'absolute',
            content: '""',
            width: '2px',
            height: 56,
            background: 'black',
            top: -56,
            marginLeft: -1,
            left: '50%',
          },
          '&:after': {
            position: 'absolute',
            content: '""',
            width: '2px',
            height: 56,
            background: 'black',
            bottom: -56,
            marginLeft: -1,
            left: '50%',
          },
          maxWidth: '36ch',
          paddingTop: 36,
          paddingBottom: 36,
          fontFamily: 'Alegreya',
          fontWeight: '500',
          lineHeight: 1.2,
          fontSize: 28,
          margin: '48px 0',
        }}
      >
        „Wüssten Sie mehr von uns wären wir nicht so berühmt für unsere
        Diskretion“
      </p>
    </div>
  </Layout>
)

export default IndexPage
